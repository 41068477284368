<template>
  <div>
    <v-row class="px-4 purchase-order-row">
      <v-col
        class="py-0 px-0 po-line-item"
        style="width: calc(100% - 320px); border-right: 1px solid #d8dbdd"
      >
        <div class="px-5 py-2 blue lighten-4">
          <table width="100%">
            <tr>
              <th width="25%">Description</th>
              <th width="25%">Due Date</th>
              <th width="25%">Item</th>
              <th width="25%">Original Amount(RM)</th>
              <!-- <th width="12%">Open Balance</th>
                <th width="15%">Payment</th> -->
            </tr>
          </table>
        </div>

        <v-expansion-panels
          multiple
          elevation="1"
          v-for="(row, index) in data"
          :key="index.id"
          v-model="panel"
          class="altclass"
        >
          <!-- {{ getdata() }} -->
          <!-- {{ row.order_items }} -->
          <v-expansion-panel class="mt-0">
            <v-expansion-panel-header>
              <!-- <h5 class="my-0 px-0 py-0">Line Items</h5> -->
              <table width="100%">
                <tr>
                  <td width="25%">
                    <div class="d-flex align-center">
                      <!-- <v-checkbox
                        class="mx-0 px-0 my-0"
                        hide-details
                        v-model="row.billSelectCheck"
                        :value="item.billSelectCheck"
                      ></v-checkbox> -->
                      <div>
                        <span class="blue--text"></span>
                        <v-chip small outlined>{{ row.barcode }}</v-chip>
                      </div>
                    </div>
                  </td>
                  <td width="26%">
                    <v-chip small outlined>
                      {{ formatedateTime(row.delivery_date) }}
                    </v-chip>
                  </td>
                  <td width="26%">
                    <v-badge class="ms-3" color="blue" :content="row.order_items.length"></v-badge>
                  </td>
                  <td width="23%">
                    <div class="ms-3">{{ row.total }}</div>
                  </td>
                  <!-- <td width="12%"><div class="ms-4">{{ row.open_balance }}</div></td>
                    <td width="13%" align="center">{{ row.payment }}</td> -->
                </tr>
              </table>
            </v-expansion-panel-header>
            <v-divider class="my-0"></v-divider>
            <v-expansion-panel-content class="px-0 py-0">
              <div class="w-100 po-line-items">
                <table width="100%">
                  <thead>
                    <tr>
                      <th class="pa-2" width="40">Image</th>
                      <th class="pa-2" width="200">Product</th>
                      <th class="pa-2" width="300">Description</th>
                      <th class="pa-2 text-center" width="100">UOM</th>
                      <th class="pa-2 text-center" width="120">Qty</th>
                      <th class="pa-2 text-center" width="120"></th>
                      <th class="pa-2" width="200">Rate</th>
                      <th class="pa-2" width="200">Amount(RM)</th>
                      <!-- <th class="pa-2 text-right" width="120">Pending</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in row.order_items" :key="index.id">
                      <td class="px-2" align="center">
                        <div>
                          <v-avatar size="35px">
                            <img
                              v-if="row.product?.transaction_documents?.file?.url"
                              max-height="40"
                              max-width="40"
                              :src="row.product?.transaction_documents?.file?.url"
                              alt="John"
                            />
                            <img
                              v-else
                              max-height="40"
                              max-width="40"
                              src="https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/files/image/png/OFVhotIVFLGfIcidqwYD9YQxR3edOtSq7cZaTji6.png"
                              alt="John"
                            />
                          </v-avatar>
                        </div>
                      </td>

                      <td class="px-2">
                        <div>{{ row.product.name_en }}</div>
                      </td>
                      <td class="px-2">
                        <div>{{ row.description }}</div>
                      </td>
                      <td class="px-2" align="center">
                        <div class="d-flex">
                          {{ row.uom }}
                          <v-badge
                            v-if="row.uom == row?.product?.primary_sales_uom"
                            :color="row.billType == 'P' ? 'green' : 'blue'"
                            inline
                          ></v-badge>
                          <v-badge
                            v-else
                            :color="row.billType == 'P' ? 'green' : 'red'"
                            inline
                          ></v-badge>
                        </div>
                      </td>
                      <td class="px-2" align="center">
                        <div>{{ row.quantity }}</div>
                      </td>
                      <td class="px-2" align="center">
                        <div></div>
                      </td>
                      <td class="px-2">
                        <div>{{ row.rate }}</div>
                      </td>
                      <!-- <td></td> -->
                      <td class="px-2">
                        <div>{{ row.total }}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div class="px-5 py-3">
          <table width="100%">
            <tfoot>
              <tr>
                <td class="py-1 borer-none text-right" colspan="8"><b>Sub Total</b></td>
                <td v-if="data.length" class="py-1 borer-none text-right" width="180">
                  {{ formatMoney(getSubtotal()) }}
                </td>
                <td v-else class="py-1 borer-none text-right" width="180">RM</td>
              </tr>
              <tr>
                <td class="py-1 borer-none text-right" colspan="8">
                  <div class="d-flex align-items-center justify-content-end">
                    <b>Discount</b>
                    <div style="max-width: 80px" class="ml-0"></div>
                    <div style="max-width: 80px" class=""></div>
                  </div>
                </td>
                <td v-if="data.length" class="py-1 borer-none text-right" width="180">
                  {{ formatMoney(getDiscount()) }}
                </td>
              </tr>
              <tr>
                <td class="py-1 borer-none text-right" colspan="8">
                  <div class="d-flex align-items-center justify-content-end">
                    <b>Tax 9 </b>
                    <div style="max-width: 60px" class="ml-2">
                      <span class="font-weight-bold" style="font-size: 16px"> % </span>
                    </div>
                  </div>
                </td>
                <td v-if="data.length" class="py-1 borer-none text-right" width="180">
                  {{ formatMoney(getTax()) }}
                </td>
              </tr>
              <tr>
                <td class="py-1 borer-none text-right" colspan="8">
                  <div class="d-flex align-items-center justify-content-end">
                    <b>Adjustment</b>
                    <div style="max-width: 60px" class="ml-0"></div>
                  </div>
                </td>
                <td v-if="data.length" class="py-1 borer-none text-right" width="180">
                  {{ formatMoney(getAdjustment()) }}
                </td>
              </tr>
              <tr>
                <td class="py-1 borer-none text-right green--text" colspan="8">
                  <b style="font-size: 20px">Grand Total</b>
                </td>
                <td class="py-1 borer-none text-right green--text" width="180">
                  <b v-if="data.length" style="font-size: 20px"> {{ formatMoney(getTotal()) }}</b>
                  <!-- <b v-if="data.length" style="font-size: 20px"> {{ selectPo() }}</b> -->
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </v-col>
      <v-col style="max-width: 320px" class="px-0 pt-0">
        <v-card elevation="1" height="100%">
          <v-list dense class="py-0 text-end">
            <!-- <div class="px-3 pt-0 pb-3 d-flex align-center">
              <div class="mb-0">
                <TextInput hide-details :disabled="pageLoading" :loading="pageLoading" id="supplier"
                  placeholder="Search..." v-model="searchSupplier" prepend-inner-icon="mdi-magnify">
                </TextInput>
              </div>
              <v-menu offset-y left transition="slide-y-transition" :close-on-content-click="false">
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn color="blue darken-4" depressed @click="showFilter = !showFilter" tile v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }" class="white--text ms-2 mt-3" height="34">
                        <v-icon>{{ showFilter ? "mdi-filter-remove" : "mdi-filter-plus" }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Filter</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item class="flex-column">
                    <v-form ref="productAddForm" v-model.trim="formValid" lazy-validation
                      v-on:submit.stop.prevent="updateOrCreate">
                      <div class="pb-2">
                        <v-select hide-details :disabled="pageLoading" :loading="pageLoading"
                          :items.sync="paymentTypeList" id="payment-type" outlined v-model="paymentType"
                          placeholder="Payment type">
                        </v-select>
                      </div>
                      <div class="pb-2">
                        <DatePicker hide-details :disabled="pageLoading" :loading="pageLoading" id="delivery-date"
                          v-model="delivery_date" placeholder="Delivery Date"></DatePicker>
                      </div>
                      <div class="text-right">
                       
                        <v-btn color="blue darken-4" @click="fill" depressed tile class="white--text ms-2">
                          Filter
                        </v-btn>
                      </div>
                    </v-form>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div> -->

            <v-divider class="my-0"></v-divider>
            <!-- {{ supplier }} -->
            <div class="puchase-listing">
              <v-list-item
                class="puchase-listing-item cursor-pointer"
                v-for="(item, i) in supplier"
                :key="i"
                style="border-bottom: 1px solid darkgrey"
              >
                <!-- {{ item.uuid }} -->

                <v-checkbox
                  class="mx-0"
                  v-model="checked"
                  :value="item.uuid"
                  :disabled="uuid.includes(item.uuid)"
                  multiple
                  v-on:click="() => getPayments(checked)"
                ></v-checkbox>
                <v-list-item-content>
                  <div class="d-flex align-center justify-space-between">
                    <div>
                      <p class="mb-2 font-weight-bold blue--text text-start">
                        {{ item.supplier }}
                      </p>
                      <div class="d-flex black--text">
                        <p class="mb-0 border-right me-2">{{ item.po }}</p>
                        <span
                          ><v-icon size="15">mdi-calendar</v-icon
                          >{{ formatedateTime(item?.delivery_date) }}</span
                        >
                      </div>
                    </div>
                    <div>
                      <p class="mb-0 font-weight-bold black--text">RM{{ item.total }}</p>
                      <div
                        class="d-flex align-center justify-space-between black--text mt-1"
                        style="max-width: 100px"
                      >
                        <v-chip
                          v-if="item.status == 'Pending' && !item.bill"
                          class="white--text"
                          small
                          outlined
                          label
                          :color="item.po_status == 'Issued' ? 'red' : 'red'"
                          >Initial Pending</v-chip
                        >
                        <v-chip
                          v-else-if="item.status == 'Pending' && item.bill"
                          class="white--text"
                          small
                          outlined
                          label
                          :color="item.status == 'Issued' ? 'orange' : 'orange'"
                          >Final Pending</v-chip
                        >
                        <v-chip
                          v-else-if="item.status == 'Initial Paid' && item.bill"
                          class="white--text"
                          small
                          outlined
                          label
                          :color="item.status == 'Issued' ? 'green' : 'indigo'"
                          >Initial Paid - {{ item.payment_mode }}</v-chip
                        >
                        <v-chip
                          v-else-if="item.status == 'Initial Paid'"
                          class="white--text"
                          small
                          outlined
                          label
                          :color="item.po_status == 'Issued' ? 'green' : 'indigo'"
                          >Initial Paid - {{ item.payment_mode }}</v-chip
                        >
                        <v-chip
                          v-else-if="item.status == 'Paid' && item.bill"
                          class="white--text"
                          small
                          outlined
                          label
                          :color="item.po_status == 'Issued' ? 'green' : 'green'"
                          >Final Paid - {{ item.payment_mode }}</v-chip
                        >
                      </div>
                      <!-- <v-chip small color="red" class="text-white">{{ item.status }}</v-chip> -->
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
//import MomentJS from "moment-timezone";
// import TextInput from "@/view/components/TextInput";
// import DatePicker from "@/view/components/DatePicker";
import { formatMoney } from "accounting-js";
import { FIND_POCHECK_BILL } from "@/core/lib/pos.lib";
import { mapGetters } from "vuex";
import { PATCH } from "@/core/services/store/request.module";
import moment from "moment-timezone";

import { QUERY } from "@/core/services/store/request.module";
// import { GET_All_SUPPLIER} from "@/core/lib/pos.lib";
// import { SET_ERROR } from "@/core/services/store/common.module";

// import { relativeTimeThreshold } from "moment-timezone";

export default {
  name: "po-line-items",
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tt: {
      type: Array,
      default: () => {
        return [];
      },
    },
    pot: {
      type: Array,
      default: () => {
        return [];
      },
    },
    co: {
      type: Array,
      default: () => {
        return [];
      },
    },

    supplier: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      panel: [0],
      formValid: false,
      showFilter: false,
      delivery_date: "",
      checked: [],
      searchSupplier: "",
      sub_total: null,
      product: [],
      uuid: [],
      paymentType: null,
      selectedItem: 0,
      paymentTypeList: [
        {
          text: "Final Paid",
          value: "Paid",
          color: "",
        },
        {
          text: "Final Pending",
          value: "final_pending",
          color: "",
        },
        {
          text: "Initial Pending",
          value: "pending",
          color: "",
        },
        {
          text: "Initial Paid",
          value: "Initial Paid",
          color: "",
        },
      ],
      billListData: [
        {
          id: 1,
          po_number: "PO0001",
          bill_date: "12/09/2023",
          due_date: "12/09/2023",
          amount: "4,750",
          open_balance: "0.00",
          payment: "Payment",
          // billSelectCheck: true,
        },
      ],
      line_items: [
        // {
        //   id: 1,
        //   product: "aircon",
        //   description: "Description",
        //   quantity: 1,
        //   order: 1,
        //   uom: "pcs",
        //   rate: "4,000.00",
        //   total: "500.00",
        //   billType: "P",
        // },
        // {
        //   id: 2,
        //   product: "iphone",
        //   description: "Description",
        //   quantity: 1,
        //   order: 1,
        //   uom: "pcs",
        //   rate: "500.00	",
        //   total: "16,000.00",
        //   billType: "S",
        // },
      ],
      items: [
        {
          id: 1,
          supplierName: "Supplier name",
          barcode: "Bill# 101",
          delivery_date: "08/09/2023",
          amount: "4,750.00",
          checkbox: true,
        },
        {
          id: 2,
          supplierName: "Supplier name",
          barcode: "Bill# 102",
          delivery_date: "08/09/2023",
          amount: "450.00",
          checkbox: false,
        },
        {
          id: 3,
          supplierName: "Supplier name",
          barcode: "Bill# 103",
          delivery_date: "08/09/2023",
          amount: "750.00",
          checkbox: false,
        },
      ],
    };
  },
  methods: {
    formatedateTime(param) {
      return moment(param).format("DD/MM/YYYY ");
    },
    fill() {
      this.date = this.delivery_date;
      this.status = this.paymentType;

      this.$store
        .dispatch(PATCH, {
          url: `purchase-order/supplier-filter`,
          data: {
            delivery_date: this.date,
            status: this.status,
          },
        })
        .then((data) => {
          this.items = data.data;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    // async  getuuid(){

    //   let Barcode = "";
    //   this.tt.map((item) => {
    //     Barcode = `${Barcode} "${item.uuid}",`;
    //     return item;
    //   })
    //   this.purchase = [Barcode]

    // },
    async getPayments() {
      // if(this.tt){
      //   let Barcode = "";
      //   this.tt.map((item) => {
      //     Barcode = `${Barcode} ${item.uuid},`;
      //     return item;
      //   })
      //   this.purchase = Barcode;
      //   console.log(this.purchase,'ffd')
      // }
      // else{

      this.purchase = this.checked;
      this.$emit("purchased", this.purchase);

      //     console.log(this.purchase,'ffd')
      // }

      // console.log(Barcode,'Barcode')
      //   this.purchase = this.checked;

      const pos = await FIND_POCHECK_BILL(this.purchase);
      this.data = pos;
      // this.bulk = pos;
      // this.single = {}

      console.log(pos, "bills");
    },
    selectPo() {
      const _this = this;
      this.$store
        .dispatch(QUERY, {
          url: `purchase-order/supplier-filter`,
        })
        .then((data) => {
          _this.items = data;

          /*  _this.purchaseOrder.supplier.barcode = data.supplier.barcode
            console.log(_this.purchaseOrder.supplier.barcode)     */
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    // async getPo() {

    //     const item = await GET_All_SUPPLIER();

    //     console.log(item, "gggg");

    // },
    formatMoney(money) {
      return formatMoney(money, {
        symbol: "RM",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
    getdata() {
      this.po = [this.data];
      console.log(this.po, "sdsmmmd");
    },
    getSubtotal() {
      // this.selectPo(this.data[0].id);
      this.product = this.data;

      let sum = 0;

      this.product?.forEach((element) => {
        sum = sum + Number(element.sub_total);
      });
      console.log(sum);
      this.sub_total = Number(sum);
      //  this.getDiscount();
      console.log(this.sub_total, "hd");
      return this.sub_total;
    },
    getDiscount() {
      this.product = this.data;

      let sum = 0;

      this.product?.forEach((element) => {
        sum = sum + Number(element.total_discount);
      });
      console.log(sum);
      this.total_discount = Number(sum);
      //  this.getDiscount();
      console.log(this.total_discount);
      return this.total_discount;
    },
    getTax() {
      this.product = this.data;

      let sum = 0;

      this.product?.forEach((element) => {
        sum = sum + Number(element.tax_amount);
      });
      console.log(sum);
      this.tax_amount = Number(sum);
      //  this.getDiscount();
      console.log(this.total_discount);
      return this.tax_amount;
    },

    getAdjustment() {
      this.product = this.data;

      let sum = 0;

      this.product?.forEach((element) => {
        sum = sum + Number(element.adjustment);
      });
      console.log(sum);
      this.adjustment = Number(sum);
      //  this.getDiscount();
      console.log(this.total_discount);
      return this.adjustment;
    },
    getTotal() {
      this.product = this.data;

      let sum = 0;

      this.product?.forEach((element) => {
        sum = sum + Number(element.total);
      });
      console.log(sum);
      this.total = Number(sum);
      //  this.getDiscount();
      console.log(this.total_discount);
      this.$emit("input", this.total);
      return this.total;
    },
    //     getLineItems(){
    //       // this.line_items =this.data
    //       const result = this.data.map((item) => {
    //   return {
    //     order: item.order_items

    //   };
    // })
    //  this.line_items =result[0].order
    //        console.log(this.line_items,'hjfhs')
    //     }
  },
  mounted() {
    // if(this.$route.params.id){
    //   alert(22)
    //   this.checked = this.co
    //     this.uuid = this.co
    //     this.getPayments(this.checked);

    // }
    if (this.data) {
      console.log(this.$route.params.id, "ppo");

      if (this.$route.query.uuid && this.tt) {
        this.tts = this.tt[0].status;
        console.log(this.tts, "ststus");
        if (this.tts == "Initial Paid" && this.pot) {
          console.log(this.tt, "pooo");
          let Barcode = this.tt.map((item) => item.uuid);
          let Barcodes = this.pot.map((item) => item.uuid);
          console.log(Barcodes, "ii");
          this.checked = Barcode;
          this.uuid = Barcode;
          console.log(Barcode, "ststus");
          this.$emit("purchased", this.uuid);
        } else {
          this.uuid = [this.$route.query.uuid];

          this.checked = [this.$route.query.uuid];
          this.$emit("purchased", this.checked);
        }

        // this.uuid = [this.$route.query.uuid];
        // this.checked = [this.$route.query.uuid]
      } else if (this.po) {
        if (this.$route.params.id) {
          this.checked = this.co;
          this.uuid = this.co;
          this.getPayments(this.checked);
        } else {
          this.uuid = this.po;
          this.checked = this.po;
          this.$emit("purchased", this.checked);
        }
      }
      // else if(this.co){
      // alert(12)

      //   this.uuid = this.co;
      //   this.checked = this.co;
      //   console.log(this.checked,'checkedpo')

      // }
      else {
        this.uuid = [this.$route.query.uuid];
        this.checked = [this.$route.query.uuid];
        // let Barcode = "";
        // this.tt.map((item) => {
        //   Barcode = `${Barcode} ${item.uuid},`;
        //   return item;
        // })
        // let Barcode =  this.tt.map((item) => item.uuid)
        // console.log(Barcode,'ii')
        // this.checked = Barcode
        // this.uuid = Barcode
      }

      // const pos = await FIND_POCHECK(item);
      console.log(this.checked, "d");

      this.getPayments(this.checked);
      console.log(this.uuid, "ss");
    }
  },
  computed: {
    ...mapGetters(["po"]),
  },
  components: {
    // TextInput,
    // DatePicker,
  },
};
</script>

<style scoped>
.th {
  font-size: 13px !important;
}

.purchase-order-row {
  margin: 0 !important;
}

.po-line-items thead {
  /* background-color: #bbdefb !important; */
  background-color: #ede8e8 !important;
}

.po-line-items th {
  font-weight: 500;
}

.po-line-items th,
.po-line-items td:not(.borer-none) {
  border-bottom: 1px solid #c7c7c7;
  /* border-left: 1px solid #c7c7c7; */
  height: 40px;
}

.po-line-items tbody tr:nth-child(even) {
  /* background-color: #e3eff9 !important; */
  background-color: #f9f1f1 !important;
}

.altclass.theme--light.v-expansion-panels
  .v-expansion-panel:nth-child(even)
  .v-expansion-panel-header {
  background-color: #e3eff9 !important;
}

.po-line-items th:first-child,
.po-line-items td:first-child {
  border-left: none !important;
}

.v-expansion-panel-content .v-expansion-panel-content__wrap {
  padding: 0px !important;
}

.puchase-listing .puchase-listing-item:nth-child(odd) {
  background-color: #f2f6fa !important;
}

.puchase-listing-item .theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.3;
}
</style>
